<template>
  <div class="content">
    <nav-bar title="详情"></nav-bar>
    <div class="main" v-html="introduction"></div>
  </div>
</template>
<script>
import NavBar from "@/components/content/navBar/NavBar.vue";
import { flfgDetails } from "@/network/flfg.js";
import { dxalDetails } from "@/network/dxal.js";
export default {
  data() {
    return {
      introduction: null,
    };
  },
  created() {
    this.introduction = JSON.parse(this.$route.query.item).introduction;
    if (this.$route.query.type) {
      this.initData(this.$route.query.type);
    }
  },
  methods: {
    async initData(type) {
      const id = JSON.parse(this.$route.query.item).id;
      console.log(type === "1");
      if (type === "1") {
        const result = await flfgDetails(id);
        this.introduction = result.data.introduction;
      } else {
        const result = await dxalDetails(id);
        this.introduction = result.data.introduction;
      }
    },
  },
  components: {
    NavBar,
  },
};
</script>
<style scoped>
.main {
  padding: 1.08rem 0.15rem;
  font-size: 0.28rem;
}
/* img {
  width: 100%;
  margin-bottom: 0.1rem;
}
h2 {
  text-align: center;
  font-size: 0.32rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} 
p {
  font-size: 0.3rem;
  line-height: 1.5;
} */
</style>